<template>
  <el-dialog
    v-drag
    :title="title"
    :visible.sync="dialogVisible"
    :append-to-body="true"
    width="800px"
    :show-close="true"
    top="5vh"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
  >
    <div v-loading="loading" class="dialog-content">
      <el-form ref="form" :model="form" :rules="rules" label-position="top">
        <el-form-item v-if="activeName === '0' || activeName === '1'" label="代码" prop="code">
          <el-input
            v-model="form.code"
            style="width: 320px"
            :maxlength="5"
            placeholder="仅限填写数字和英文字符，最多5位"
            @input="onInput('code')"
          ></el-input>
        </el-form-item>
        <el-form-item label="国家/省州" prop="cascader">
          <el-cascader
            ref="myCascader"
            v-model="form.cascader"
            style="width: 320px"
            placeholder="请选择国家"
            :options="addressDropCodeOption"
            @change="cascaderChange"
          >
          </el-cascader>
        </el-form-item>
        <el-form-item label="城市" prop="city">
          <el-input
            v-model="form.city"
            style="width: 320px"
            placeholder="仅限填写数字和英文字符"
            @input="onInput('city')"
          ></el-input>
        </el-form-item>
        <el-form-item prop="postalCode" label="邮编">
          <el-input
            v-model="form.postalCode"
            style="width: 320px"
            placeholder="仅限填写数字和英文字符，最多12位"
            :maxlength="12"
            @input="onInput('postalCode')"
          >
            <template slot-scope="{ item }">
              <div class="name">{{ item.value }}</div>
              <span class="addr">{{ item.address }}</span>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item label="联系人" prop="attentionName">
          <el-input
            v-model="form.attentionName"
            style="width: 320px"
            placeholder="仅限除中文的字符"
            @input="onInput('attentionName')"
          ></el-input>
        </el-form-item>
        <el-form-item prop="phone">
          <span slot="label" class="label-title">
            联系电话<span>(最少10位，最多15位)</span>
          </span>
          <el-input
            v-model="form.phone"
            style="width: 320px"
            placeholder="仅限输入数字、英文括号、横杠字符"
            :maxlength="15"
            @input="onInput('phone')"
          ></el-input>
        </el-form-item>
        <el-form-item label="公司名称" prop="companyName">
          <el-input
            v-model="form.companyName"
            style="width: 320px"
            placeholder="仅限除中文的字符"
            :disabled="!!editData.id"
            @input="onInput('companyName')"
          ></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input
            v-model="form.email"
            style="width: 320px"
            placeholder="请填写邮箱"
            @input="onInput('email')"
          ></el-input>
        </el-form-item>
        <el-form-item
          v-if="activeName === '20'"
          label="税号"
          class="taxNum"
          prop="taxNumber"
        >
          <span v-if="activeName === '20'" slot="label" class="form-label">
            税号
            <el-tooltip class="item" effect="dark" placement="right">
              <div slot="content">美国EIN号码格式为：12-345678900<br>离岸进口商EIN号码格式为：123456-78900</div>
              <img src="@/assets/images/seting/question.png" alt="">
            </el-tooltip>
          </span>
          <el-input
            v-model="form.taxNumber"
            style="width: 320px"
            placeholder="仅限填写数字和横杠字符"
            @input="onInput('taxNumber')"
          ></el-input>
        </el-form-item>
        <el-form-item label="详细地址一" prop="addressLine1">
          <el-input
            v-model="form.addressLine1"
            style="width: 672px;"
            placeholder="仅限除中文的字符，最多输入50位"
            :maxlength="50"
            @input="onInput('addressLine1')"
          ></el-input>
        </el-form-item>
        <el-form-item label="详细地址二" prop="addressLine2">
          <el-input
            v-model="form.addressLine2"
            style="width: 672px;"
            placeholder="仅限除中文的字符，最多输入50位"
            :maxlength="50"
            @input="onInput('addressLine2')"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="footers">
      <div class="cancel flex_c_c" @click="cancel">取 消</div>
      <div v-loading="loading" class="ok flex_c_c" @click="ok('form')">
        确 定
      </div>
    </span>
  </el-dialog>
</template>
<script>
import { saveAddressBook, getAddressFill } from '@/api/basic'
import { addressValidation } from '@/api/system'
import { isValidEmail } from '@/utils/validate'
import { mapGetters } from 'vuex'
import config from '@/utils/config'
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    editData: {
      type: Object,
      default: function() {
        return {}
      }
    },
    activeName: {
      type: String,
      default: '0'
    }
  },
  data() {
    const validatePhone = (rule, value, callback) => {
      if (value.length < 10) {
        return callback(new Error('电话格式不正确'))
      } else {
        callback()
      }
    }
    const validateEmail = (rule, value, callback) => {
      if (!value) {
        callback()
      } else if (!isValidEmail(value)) {
        callback(new Error('请输入正确的邮箱'))
      } else {
        callback()
      }
    }
    return {
      options: [],
      form: {
        cascader: [],
        code: '',
        addressLine1: '',
        addressLine2: '',
        postalCode: '',
        phone: '',
        attentionName: '',
        companyName: '',
        email: '',
        countryCode: '',
        state: '',
        city: '',
        taxNumber: '',
        suCode: ''
      },
      loading: false,
      title: '新增-私仓',
      rules: {
        addressLine1: [
          { required: true, message: '请输入地址1', trigger: 'blur' }
        ],
        code: [{ required: true, message: '请填写代码', trigger: 'blur' }],
        phone: [
          { required: true, message: '请填写联系电话', trigger: 'blur' },
          { validator: validatePhone }
        ],
        email: [{ validator: validateEmail }],
        postalCode: [
          { required: true, message: '请填写邮编', trigger: 'blur' }
        ],
        city: [{ required: true, message: '请输入城市', trigger: 'blur' }],
        countryCode: [
          { required: true, message: '请选择国家', trigger: 'change' }
        ],
        state: [{ required: true, message: '请输入省份', trigger: 'change' }],
        // taxNumber: [{ required: true, message: '请输入税号', trigger: 'blur' }],
        companyName: [
          { required: true, message: '请输入公司名称', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    ...mapGetters(['addressDropOption']),
    addressDropCodeOption() {
      return this.addressDropOption.map((item) => {
        return {
          value: item.code,
          label: `${item.code} - ${item.label}`,
          children: item.children.map(a => {
            return {
              label: a.label,
              value: a.code
            }
          })
        }
      })
    }
  },
  watch: {
    dialogVisible(val) {
      if (val) {
        this.$refs['form']?.resetFields()
        this.code = ''
        let str = ''
        switch (this.activeName) {
          case '0':
            str = this.editData.id ? '修改-私仓' : '新增-私仓'
            break
          // case '1': str = this.editData.id ? '新增-私仓' : '修改-私仓'; break
          case '20':
            str = this.editData.id ? '修改-进口商基础信息' : '新增-进口商基础信息'
            break
          // case '3':
          //   str = this.editData.id ? '修改-收货/件方' : '新增-收货/件方'
          //   break
          // case '4':
          //   str = this.editData.id ? '修改-进口商' : '新增-进口商'
          //   break
          // case '5':
          //   str = this.editData.id ? '修改-买家' : '新增-买家'
          //   break
          // case '6':
          //   str = this.editData.id ? '修改-卖家' : '新增-卖家'
          //   break
          // case '7':
          //   str = this.editData.id ? '修改-制造商' : '新增-制造商'
          //   break
          // case '8':
          //   str = this.editData.id ? '修改-拼货方' : '新增-拼货方'
          //   break
          // case '9':
          //   str = this.editData.id ? '修改-货物送达方' : '新增-货物送达方'
          //   break
          // case '10':
          //   str = this.editData.id ? '修改-装货地' : '新增-装货地'
          //   break
          default:
            str = this.editData.id ? '修改-私仓' : '新增-私仓'
            break
        }
        this.title = str
        if (this.editData.id) {
          this.form = {
            ...this.editData,
            cascader: [this.editData.countryCode, this.editData.suCode]
          }
        } else {
          this.form = {
            cascader: [],
            code: '',
            addressLine1: '',
            addressLine2: '',
            postalCode: '',
            phone: '',
            attentionName: '',
            companyName: '',
            email: '',
            countryCode: '',
            state: '',
            city: '',
            taxNumber: '',
            suCode: ''
          }
        }
      }
    }
  },
  mounted() {
    if (!this.addressDropOption || this.addressDropOption.length === 0) {
      this.$store.dispatch('getAddress')
    }
  },
  methods: {
    onInput(type) {
      type === 'code' && this.$set(this.form, 'code', this.form.code.replace(/[^0-9a-zA-Z]/g, '').toUpperCase())
      type === 'postalCode' && this.$set(this.form, 'postalCode', this.form.postalCode.replace(/[^0-9a-zA-Z]/g, ''))
      type === 'state' && this.$set(this.form, 'state', this.form.state.replace(/[^\da-zA-Z]/g, ''))
      type === 'city' && this.$set(this.form, 'city', this.form.city.replace(/[^\da-zA-Z ]/g, ''))
      type === 'attentionName' && this.$set(this.form, 'attentionName', this.form.attentionName.replace(/[\u4e00-\u9fa5]/g, ''))
      type === 'phone' && this.$set(this.form, 'phone', this.form.phone.replace(/[^0-9()-]/g, ''))
      type === 'companyName' && this.$set(this.form, 'companyName', this.form.companyName.replace(/[\u4e00-\u9fa5]/g, ''))
      type === 'taxNumber' && this.$set(this.form, 'taxNumber', this.form.taxNumber.replace(/[^0-9\-]/g, ''))
      type === 'addressLine1' && this.$set(this.form, 'addressLine1', this.form.addressLine1.replace(/[\u4e00-\u9fa5]/g, ''))
      type === 'addressLine2' && this.$set(this.form, 'addressLine2', this.form.addressLine2.replace(/[\u4e00-\u9fa5]/g, ''))
    },
    cancel() {
      this.$emit('cancel')
    },
    ok(formName) {
      if (this.form.cascader.length && this.form.cascader[0] !== 'US') {
        const parts = this.$refs['myCascader'].inputValue.split('/')
        const state = parts[1].trim()
        this.form.state = state
      }
      const params = {
        ...this.form,
        city: this.form.city.trim(),
        companyName: this.form.companyName.trim(),
        addressLine1: this.form.addressLine1.trim(),
        addressLine2: this.form.addressLine2 ? this.form.addressLine2.trim() : '',
        type: Number(this.activeName),
        supplierId: config.supplyCode
      }
      this.$refs[formName].validate(async(valid) => {
        if (valid) {
          this.loading = true
          if (!parseInt(this.activeName, 10)) {
            const res1 = await addressValidation(params)
            if (res1.success) {
              params.residential = res1.data.classification
            }
          }
          saveAddressBook(params).then((res) => {
            if (res.success) {
              this.$message.success(res.errorMessage)
              this.$emit('ok')
              this.loading = false
            } else {
              this.$message.error(res.errorMessage)
              this.loading = false
            }
          })
        } else {
          return false
        }
      })
    },
    async querySearchAsync(queryString, cb) {
      if (!this.form.countryCode) {
        this.$message.error('请先选择国家')
        return []
      }
      try {
        const res = await getAddressFill({
          countryCode: this.form.countryCode,
          postalCode: queryString
        })
        if (res.success) {
          const arr = res.data.map((item) => {
            return {
              value: item.postalCode,
              address: `${item.cityName}, ${item.provinceCode},${item.postalCode}`
            }
          })
          cb(arr)
          console.log(res)
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.log(error)
      }
    },
    handleSelect(item) {
      const city = item.address.split(',')[0]
      // const state = item.address.split(',')[1].trim()
      // this.form.state = state
      this.form.city = city
    },
    cascaderChange(v) {
      this.form.countryCode = this.form.cascader[0]
      this.form.suCode = this.form.cascader[1]
      if (v[0] === 'US') {
        this.form.state = this.form.cascader[1]
      }
    }
  }
}
</script>
<style lang="less" scoped>
.dialog-content {
  width: calc(100% - 88px);
  margin: 0 auto;
}
.footers {
  display: flex;
  justify-content: center;
  & div {
    width: 120px;
    height: 40px;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
  }
  .cancel {
    background: #eeeeee;
    color: #999999;
  }
  .ok {
    background: #2395f3;
    color: #ffffff;
    margin-left: 20px;
    cursor: pointer;
  }
}
.el-form {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
/deep/ .el-form--label-top .el-form-item__label {
  padding: 0 0 0 0;
}
/deep/ .el-form-item {
  margin-bottom: 10px;
}
/deep/ .el-form-item__label {
  line-height: 30px !important;
}
/deep/ .el-col:nth-child(2n) {
  display: flex;
  justify-content: flex-end;
}
.label-title {
  span {
    color: red;
    font-size: 12px;
  }
}
/deep/ .taxNum .el-form-item__label {
  display: flex;
}
.form-label {
  display: flex;
  align-items: center;
}
</style>
