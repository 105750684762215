var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [{ name: "drag", rawName: "v-drag" }],
      attrs: {
        title: _vm.title,
        visible: _vm.dialogVisible,
        "append-to-body": true,
        width: "800px",
        "show-close": true,
        top: "5vh",
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "before-close": _vm.cancel,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "dialog-content",
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-position": "top",
              },
            },
            [
              _vm.activeName === "0" || _vm.activeName === "1"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "代码", prop: "code" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "320px" },
                        attrs: {
                          maxlength: 5,
                          placeholder: "仅限填写数字和英文字符，最多5位",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.onInput("code")
                          },
                        },
                        model: {
                          value: _vm.form.code,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "code", $$v)
                          },
                          expression: "form.code",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "国家/省州", prop: "cascader" } },
                [
                  _c("el-cascader", {
                    ref: "myCascader",
                    staticStyle: { width: "320px" },
                    attrs: {
                      placeholder: "请选择国家",
                      options: _vm.addressDropCodeOption,
                    },
                    on: { change: _vm.cascaderChange },
                    model: {
                      value: _vm.form.cascader,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "cascader", $$v)
                      },
                      expression: "form.cascader",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "城市", prop: "city" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "320px" },
                    attrs: { placeholder: "仅限填写数字和英文字符" },
                    on: {
                      input: function ($event) {
                        return _vm.onInput("city")
                      },
                    },
                    model: {
                      value: _vm.form.city,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "city", $$v)
                      },
                      expression: "form.city",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "postalCode", label: "邮编" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "320px" },
                    attrs: {
                      placeholder: "仅限填写数字和英文字符，最多12位",
                      maxlength: 12,
                    },
                    on: {
                      input: function ($event) {
                        return _vm.onInput("postalCode")
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ item }) {
                          return [
                            _c("div", { staticClass: "name" }, [
                              _vm._v(_vm._s(item.value)),
                            ]),
                            _c("span", { staticClass: "addr" }, [
                              _vm._v(_vm._s(item.address)),
                            ]),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.form.postalCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "postalCode", $$v)
                      },
                      expression: "form.postalCode",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "联系人", prop: "attentionName" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "320px" },
                    attrs: { placeholder: "仅限除中文的字符" },
                    on: {
                      input: function ($event) {
                        return _vm.onInput("attentionName")
                      },
                    },
                    model: {
                      value: _vm.form.attentionName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "attentionName", $$v)
                      },
                      expression: "form.attentionName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "phone" } },
                [
                  _c(
                    "span",
                    {
                      staticClass: "label-title",
                      attrs: { slot: "label" },
                      slot: "label",
                    },
                    [
                      _vm._v(" 联系电话"),
                      _c("span", [_vm._v("(最少10位，最多15位)")]),
                    ]
                  ),
                  _c("el-input", {
                    staticStyle: { width: "320px" },
                    attrs: {
                      placeholder: "仅限输入数字、英文括号、横杠字符",
                      maxlength: 15,
                    },
                    on: {
                      input: function ($event) {
                        return _vm.onInput("phone")
                      },
                    },
                    model: {
                      value: _vm.form.phone,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "phone", $$v)
                      },
                      expression: "form.phone",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "公司名称", prop: "companyName" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "320px" },
                    attrs: {
                      placeholder: "仅限除中文的字符",
                      disabled: !!_vm.editData.id,
                    },
                    on: {
                      input: function ($event) {
                        return _vm.onInput("companyName")
                      },
                    },
                    model: {
                      value: _vm.form.companyName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "companyName", $$v)
                      },
                      expression: "form.companyName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "邮箱", prop: "email" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "320px" },
                    attrs: { placeholder: "请填写邮箱" },
                    on: {
                      input: function ($event) {
                        return _vm.onInput("email")
                      },
                    },
                    model: {
                      value: _vm.form.email,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "email", $$v)
                      },
                      expression: "form.email",
                    },
                  }),
                ],
                1
              ),
              _vm.activeName === "20"
                ? _c(
                    "el-form-item",
                    {
                      staticClass: "taxNum",
                      attrs: { label: "税号", prop: "taxNumber" },
                    },
                    [
                      _vm.activeName === "20"
                        ? _c(
                            "span",
                            {
                              staticClass: "form-label",
                              attrs: { slot: "label" },
                              slot: "label",
                            },
                            [
                              _vm._v(" 税号 "),
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: { effect: "dark", placement: "right" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      attrs: { slot: "content" },
                                      slot: "content",
                                    },
                                    [
                                      _vm._v("美国EIN号码格式为：12-345678900"),
                                      _c("br"),
                                      _vm._v(
                                        "离岸进口商EIN号码格式为：123456-78900"
                                      ),
                                    ]
                                  ),
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/images/seting/question.png"),
                                      alt: "",
                                    },
                                  }),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("el-input", {
                        staticStyle: { width: "320px" },
                        attrs: { placeholder: "仅限填写数字和横杠字符" },
                        on: {
                          input: function ($event) {
                            return _vm.onInput("taxNumber")
                          },
                        },
                        model: {
                          value: _vm.form.taxNumber,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "taxNumber", $$v)
                          },
                          expression: "form.taxNumber",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "详细地址一", prop: "addressLine1" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "672px" },
                    attrs: {
                      placeholder: "仅限除中文的字符，最多输入50位",
                      maxlength: 50,
                    },
                    on: {
                      input: function ($event) {
                        return _vm.onInput("addressLine1")
                      },
                    },
                    model: {
                      value: _vm.form.addressLine1,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "addressLine1", $$v)
                      },
                      expression: "form.addressLine1",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "详细地址二", prop: "addressLine2" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "672px" },
                    attrs: {
                      placeholder: "仅限除中文的字符，最多输入50位",
                      maxlength: 50,
                    },
                    on: {
                      input: function ($event) {
                        return _vm.onInput("addressLine2")
                      },
                    },
                    model: {
                      value: _vm.form.addressLine2,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "addressLine2", $$v)
                      },
                      expression: "form.addressLine2",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        { staticClass: "footers", attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "div",
            { staticClass: "cancel flex_c_c", on: { click: _vm.cancel } },
            [_vm._v("取 消")]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "ok flex_c_c",
              on: {
                click: function ($event) {
                  return _vm.ok("form")
                },
              },
            },
            [_vm._v(" 确 定 ")]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }